const firebaseConfig = {
    apiKey: "AIzaSyB_JXckVAWsW8bbefzsIWuvB5TulD0b2uM",
    authDomain: "chat-909aa.firebaseapp.com",
    databaseURL: "https://chat-909aa.firebaseio.com",
    projectId: "chat-909aa",
    storageBucket: "chat-909aa.appspot.com",
    messagingSenderId: "458591888526",
    appId: "1:458591888526:web:1f45707d45ef6e955815df"
};

export default firebaseConfig;